// Packages
import React from "react";

// Components
import Container from "~components/common/Container";
import P from "~components/typography/P";
import Button from "~components/common/Button";
import H2 from "~components/typography/H2";

interface Props {
	title: string;
	description: string;
	button: {
		text: string;
		to?: string;
		href?: string;
		onClick?: () => void;
		anchor?: string;
	};
}

const CtaBox: React.FC<Props> = ({ title, description, button }) => {
	return (
		<div className="bg-primary-100 p-4">
			<H2 display as="p" className="mb-4">
				{title}
			</H2>
			<P className="mb-6">{description}</P>
			<Button
				text={button.text}
				href={button.href ? button.href : undefined}
				to={button.to ? button.to : undefined}
				onClick={button.onClick ? button.onClick : undefined}
				anchor={button.anchor ? button.anchor : undefined}
				type="secondary"
				className="w-full"
			/>
		</div>
	);
};

export default CtaBox;
