import React from "react";

interface Props {
	children: React.ReactNode;
	as?: string;
	className?: string;
	textColor?: string;
}

const SubTitle: React.FC<Props> = ({ children, as = "span", className, textColor = "text-primary-500" }) => {
	const classes = `font-serif font-bold uppercase ${textColor} ${className ? className : ""}`;
	return React.createElement(as, { className: classes }, children);
};

export default SubTitle;
