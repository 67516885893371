import React from "react";
import { CheckCircleIcon } from "@heroicons/react/outline";

interface Props {
	items: string[] | React.ReactNode[];
	className?: string;
	pretty?: boolean;
	textColor?: string;
}

const Ul: React.FC<Props> = ({ items, className, pretty = false, textColor = "text-gray-600" }) => {
	return (
		<ul className={`mt-3 space-y-3 ${!pretty ? "list-disc pl-5" : ""} ${textColor} ${className ? className : ""} `}>
			{items.map((item, i) => (
				<li key={i} className={pretty ? "flex" : undefined}>
					{pretty ? <CheckCircleIcon className="mr-3 mt-1 inline-block h-4 w-4 flex-shrink-0 text-primary" /> : null}
					<div>{item}</div>
				</li>
			))}
		</ul>
	);
};

export default Ul;
