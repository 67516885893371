import React, { isValidElement } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

// Types
import { IGatsbyImageData } from "gatsby-plugin-image";
import Grid from "~components/common/Grid";
import Container from "~components/common/Container";

interface Props {
	children: React.ReactNode;
	element?: React.ReactNode;
	image?: IGatsbyImageData;
	alt?: string;
	align?: "center" | "start" | "end";
}

const Hero: React.FC<Props> = ({ children, image, element, alt, align = "center" }) => {
	const gatsbyImage = image ? getImage(image) : null;
	return (
		<Container backgroundColor="bg-primary-100" noMargin>
			<Grid cols={2} items={align}>
				<div className="py-8 md:py-16">{children}</div>
				{isValidElement(element) ? element : null}
				{gatsbyImage ? (
					<div>
						<GatsbyImage image={gatsbyImage} alt={alt ? alt : ""} title={alt ? alt : ""} className="rounded" />
					</div>
				) : null}
			</Grid>
		</Container>
	);
};

export default Hero;
