// Packages
import React from "react";
import { graphql } from "gatsby";

// Types
import type { SiteMetadata, Location } from "~types/Gatsby";
import type { IGatsbyImageData } from "gatsby-plugin-image";

// Components
import Layout from "~components/layouts/Layout";
import Button from "~components/common/Button";
import Container from "~components/common/Container";
import Grid from "~components/common/Grid";
import SubTitle from "~components/typography/SubTitle";
import H1 from "~components/typography/H2";
import H2 from "~components/typography/H2";
import P from "~components/typography/P";
import Hero from "~components/sections/Hero";
import Ul from "~components/typography/Ul";
import Blockquote from "~components/typography/Blockquote";
import TestmonialSingle from "~components/sections/TestmonialSingle";
import CtaBox from "~components/common/CtaBox";
import Alert from "~components/common/Alert";

interface DataProps {
	site: {
		siteMetadata: SiteMetadata;
	};
	imageHeader: IGatsbyImageData;
	profileImage: IGatsbyImageData;
	imageTestmonial: IGatsbyImageData;
}

export default function Page({ data, location }: { data: DataProps; location: Location }) {
	return (
		<Layout
			location={location}
			title="Coaching"
			desc=""
			// imageFacebook={`${data.site.siteMetadata.siteUrl}${data.seoImage.facebook.gatsbyImageData.images.fallback.src}`}
			// imageFacebookWidth={data.seoImage.facebook.gatsbyImageData.width}
			// imageFacebookHeight={data.seoImage.facebook.gatsbyImageData.height}
			// imageTwitter={`${data.site.siteMetadata.siteUrl}${data.seoImage.twitter.gatsbyImageData.images.fallback.src}`}
		>
			<Hero image={data.imageHeader} alt="Coaching mit Daniel Thiel">
				<SubTitle className="mb-2">Business Coaching 1:1</SubTitle>
				<H1 display className="mb-4">
					Coaching zum Aufbau oder Ausbau Deines Geschäfts
				</H1>
				<P className="mb-4 font-bold">
					Maximale und profitable Erreichung Deiner Ziele für mehr Zeit, Umsatz und Freiheit im Leben
				</P>
				<P>Du bist Selbständig oder Unternehmer? Vielleicht stellst Du Dir dann genau diese Fragen:</P>
				<Ul
					items={[
						<>
							Du möchtest diese <span className="font-bold">Innere Ruhe</span> und das Gefühl mit Deinem Unternehmen{" "}
							<span className="font-bold">„angekommen“</span> zu sein?
						</>,
						<>
							Du möchtest <span className="font-bold">Sicherheit</span> durch profitable, regelmäßige und{" "}
							<span className="font-bold">planbare monatliche Umsätze</span>?
						</>,
						<>
							Du möchtest <span className="font-bold">frei von finanziellen Engpässen</span>, Sorgen und negativen
							Gedanken leben?
						</>,
						<>
							Du möchtest ein Unternehmen, dass <span className="font-bold">auch ohne Dich</span> funktioniert, damit Du
							mit Deiner Familie und Freunden mehr Zeit verbringen kannst?
						</>,
					]}
					className="mb-4"
					pretty
				></Ul>

				<P className="mb-10">
					In meinem Coaching finden wir die Antworten und die Lösungen die Du benötigst um Dein Leben zu genießen.Bewirb
					Dich jetzt um ein 1:1 Business Coaching. Dieses Coaching ist einzigartig und wird Dein Leben positiv
					verändern.
				</P>
				{/* <Ul
					items={[
						"Löse Blockarden",
						"Schärfe Dein Mindset",
						"Vergangenheit und Zukunft",
						"Finde Dein Warum",
						"Erkenne Deine Stolpersteine",
						"Erkenne Deine Persönlichkei",
						"Erkenne Deine Stärken",
						"Entwickle Deine eigene Strategie",
					]}
					className="mb-10"
					pretty
				></Ul> */}
				<Button to="/coaching/anfragen/" text="Termin vereinbaren" />
			</Hero>

			<Container>
				<SubTitle className="mb-2">Leistungsspektrum</SubTitle>
				<H2 className="mb-4">Unser Business-Coaching</H2>
				<Grid cols={2}>
					<div>
						<P className="mb-4">
							Jeder Mensch, der <span className="font-bold">überdurchschnittliche Erfolge</span> erzielt, hat einen
							Coach. Egal ob Sportler, Manager, Musiker, Politiker oder Unternehmer. Wie ist das bei dir?
						</P>
						<P className="mb-4">
							Alle unsere Probleme, aber auch alle unsere Lösungen befinden sich in unserem{" "}
							<span className="font-bold">Kopf</span>, <span className="font-bold">Herz</span> und{" "}
							<span className="font-bold">Bauch</span>. Was hält Dich wirklich auf?
						</P>
						<P className="mb-4">
							Nicht alles, <span className="font-bold">was zählt</span>, ist <span className="font-bold">messbar</span>,
							und nicht alles, was messbar ist, zählt.
						</P>
						<P className="mb-4">
							In meinem Coaching bekommst Du Klarheit auf das was Du wirklich willst. Gemeinsam finden wir was Dich
							aufhält Dein volles Potential zu entfalten. Komm in mein Coaching. Ich freue mich auf Dich. Der Mensch ist
							ein Mosaik an Bedürfnissen. Wir finden die Lösung für Dein Problem! Lebenskrisen sind hausgemachte, eigene
							Wahrnehmungskrisen, denn die wirklich wichtigen Schlachten im Leben, schlagen wir alleine.
						</P>
						<P className="mb-4">
							Mit unserem System haben wir hunderten von Selbständigen und Unternehmern geholfen ihr volles Potential zu
							entfalten, ihr eigenes Warum zu definieren und eine individuelle Strategie zu nutzen wie man ein
							erfolgreiches und glückliches Leben führt.
						</P>
						<P>Die Antworten sind in dir!</P>
					</div>
					<div>
						<P>Das erwartet Dich in meinem Coaching</P>
						<Ul
							items={[
								"Löse Blockaden … was hält Dich wirklich auf?",
								"Schärfe Dein Mindset …think BIG und fokussiere",
								"Vergangenheit und Zukunft …Freund oder Feind?",
								"Finde Dein Warum … worum geht es dir wirklich?",
								"Erkenne Deine Stolpersteine …Gewohnheiten erkennen",
								"Erkenne Deine Persönlichkeit …Worauf bist Du die Antwort?",
								"Erkenne Deine Stärken … bewusste und unbewusste Kompetenzen",
								"Entwickle Deine eigene Strategie … Handelst Du nach Deinem WARUM?",
							]}
							pretty
							className="mb-10"
						/>
						<P>
							Mein Team ist eine Expertengruppe aus Managern, Psychologen, Business Coaches, spirituellen Trainern,
							Mindset-Trainern für Soldaten und Experten im Unternehmertum. Je nach Bedarf und individueller Situation
							findet ein zusätzlicher Baustein statt um die Lösungen und die erforderlichen Antworten zu finden, damit
							ich das versprechen an Dich einhalten kann. Wir gehen den Weg gemeinsam. Sicher dir einen Platz in meinem
							Coaching und pack es an. Ich freue mich auf Dich.
						</P>
					</div>
				</Grid>
			</Container>

			<Container>
				<Grid cols={2}>
					<Alert
						type="information"
						title="Achtung"
						message="Wir haben pro Jahr eine begrenzte Anzahl an Kunden, die wir in unser Coaching-Programm aufnehmen. Wir nehmen uns für jeden Kunden sehr viel Zeit. Ich bin besessen von der Zielerreichung meiner Kunden. Das schätzen, spüren und genießen meine Kunden an mir und meinem Team."
						className="mb-4"
					/>
					<CtaBox
						title="Investiere in Dich selbst!"
						description="Termin buchen und ein kostenloses 15-minütiges und unverbindliches Gespräch nutzen."
						button={{ text: "Termin vereinbaren", to: "/coaching/anfragen/" }}
					/>
				</Grid>
			</Container>

			<Container>
				<div className="mb-4 text-center">
					<SubTitle className="mb-2">Dein Potential</SubTitle>
					<H2>Ist das Coaching das Richtige für Dich?</H2>
				</div>
				<Grid cols={2}>
					<div>
						<Ul
							items={[
								"Dein Potential ist dir vollständig bekannt, entdeckt, gefördert und wird genutzt?",
								"Du erreichst alle Ziele, die Du Dir mit Deinem Unternehmen gesetzt hast? Zeitlich?",
								"Sämtliche Blockaden sind privat und geschäftlich gelöst und Du kannst frei durchstarten?",
								"Du blickst positiv auf die Vergangenheit und Deine Misserfolge?",
								"Du kennst genau Dein Warum und weißt genau was Du wie zu tun hast?",
								"Du machst die wichtigen Dinge richtig und bist völlig fokussiert?",
								"Du bist beim Beantworten der Fragen komplett ehrlich zu dir gewesen?",
							]}
							pretty
						/>
					</div>
					<div>
						<P className="mb-3">
							Damit wir prüfen können, ob unser Coaching für Dich und Deine Situation der richtige Baustein ist, bitte
							ich Dich, die vorangegangenen Fragen zu beantworten.
						</P>
						<P className="mb-3">
							Wenn Deine Antwort mehr als zweimal „Nein“ ist, hast Du ein Problem! In meinem Coaching bekommst Du alles
							was Du benötigst um das Problem zu lösen. Die Antwort liegt in dir selbst! Die Antwort ist nur für Dich
							nicht oder nicht mehr sichtbar. Das werden wir ändern.
						</P>
						<P className="mb-3">
							Bewirb Dich jetzt für Deinen Platz in meinem Business Coaching. Wir sprechen über Deine individuelle und
							persönliche Strategie, wie Du das Gefühl von{" "}
							<span className="font-bold">„angekommen sein und innere Ruhe“</span> bekommst, wie Du{" "}
							<span className="font-bold">monatliche Sicherheiten</span> und{" "}
							<span className="font-bold">Planbarkeit</span> erzielst, wie Du Dein Unternehmen{" "}
							<span className="font-bold">profitabel</span> machst und trotzdem{" "}
							<span className="font-bold">mehr Freiheit</span> bekommst, damit Du für die Dinge im Leben Zeit hast, die
							dir wirklich wichtig sind und Spaß machen.
						</P>
					</div>
				</Grid>
			</Container>

			<TestmonialSingle
				imageData={data.imageTestmonial}
				quote="Ich benutze zeitlose Philosophien, wissenschaftliche Wahrheiten, spirituelle Erfahrungen und bewährte Taktiken, um Dich zu verbessern. Verstecke Dich nicht hinter Deinem Umständen. Verantworte Dein Hier und Jetzt."
				alt="Daniel Thiel Zitat"
				author="Daniel Thiel"
			/>
		</Layout>
	);
}

export const query = graphql`
	{
		site {
			siteMetadata {
				siteUrl
			}
		}
		seoImage: file(relativePath: { eq: "placeholder-meta.png" }) {
			facebook: childImageSharp {
				gatsbyImageData(height: 630, width: 1200, formats: JPG, quality: 100)
			}
			twitter: childImageSharp {
				gatsbyImageData(aspectRatio: 1, formats: JPG, quality: 100)
			}
		}

		imageHeader: file(relativePath: { eq: "pages/coaching/coaching_header.jpg" }) {
			childImageSharp {
				gatsbyImageData
			}
		}

		imageTestmonial: file(relativePath: { eq: "pages/coaching/coaching_testmonial.jpg" }) {
			childImageSharp {
				gatsbyImageData
			}
		}
	}
`;
